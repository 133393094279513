import type {ConnectorSpec} from '@cohort/shared/apps/app';
import type {BaseProfileProperties, ConnectorStruct} from '@cohort/shared/apps/app';
import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import {urlTransformer, urlValidator} from '@cohort/shared/utils/validators';
import {z} from 'zod';

export const MaxxingCredentialsSchema = z.object({
  backOfficeApiUrl: z
    .string()
    .refine(urlValidator, 'errorUrl')
    .transform(url => urlTransformer(url)),
  computeApiUrl: z
    .string()
    .refine(urlValidator, 'errorUrl')
    .transform(url => urlTransformer(url)),
  accessToken: z.string().refine(token => token.startsWith('Basic ')),
  storeNumber: z.number().min(0),
});
export type MaxxingCredentials = z.infer<typeof MaxxingCredentialsSchema>;

export type MaxxingConnectorStruct = ConnectorStruct<
  'maxxing',
  null,
  MaxxingCredentials,
  BaseProfileProperties,
  null
>;

export const MaxxingConnectorSpec = {
  id: 'maxxing',
  type: 'custom',
  appCredentialsSchema: null,
  credentialsSchema: MaxxingCredentialsSchema,
  profilePropertiesSchema: BaseProfilePropertiesSchema,
  connectionConfigSchema: null,
} satisfies ConnectorSpec<MaxxingConnectorStruct>;
