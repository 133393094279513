import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortReferralCompletedPropertiesSchema = z.object({
  campaignId: z.string(),
  campaignInternalName: z.string(),
  userId: z.string(),
});
export type CohortReferralCompletedProperties = z.infer<
  typeof CohortReferralCompletedPropertiesSchema
>;

export type CohortReferralCompletedEventStruct = UserEventStruct<
  'cohort.referral.completed',
  keyof CohortReferralCompletedProperties,
  CohortReferralCompletedProperties,
  CohortReferralCompletedProperties
>;

export const CohortReferralCompletedEventSpec: UserEventSpec<CohortReferralCompletedEventStruct> = {
  id: 'cohort.referral.completed',
  name: 'Completed a referral program',
  description: 'Event Triggerred when a user completes a referral program',
  propertiesSchema: CohortReferralCompletedPropertiesSchema,
  exposedPropertiesSchema: CohortReferralCompletedPropertiesSchema,
  resources: {},
  rulesEngineConfig: {
    isVisible: true,
    exposedFields: [],
  },
};
