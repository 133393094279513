import i18n from '@cohort/components-xps/lib/i18n';
import type {CohortFillFormTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/fillForm';
import type {CohortFormConfig} from '@cohort/shared/schema/common/cohortForm';
import {buildLocalizationConfig, formatI18nLanguage} from '@cohort/shared/utils/localization';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import CohortFormModal from '@cohort/wallet/apps/cohort-form/modalStepper/CohortFormModal';
import CohortFormPromptInput from '@cohort/wallet/apps/cohort-form/modalStepper/CohortFormPromptInput';
import type {TriggerIntegrationUsageComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import Button from '@cohort/wallet/components/button/Button';
import useGetLocalizedString from '@cohort/wallet/hooks/getLocalizedString';
import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import {useScreenSize} from '@cohort/wallet/hooks/useScreenSize';
import {useIsMutating} from '@tanstack/react-query';
import type {Dispatch, SetStateAction} from 'react';
import {Fragment, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Navigate} from 'react-router-dom';

type CohortFormPreviewProps = {
  config: CohortFormConfig;
  isVerifying: boolean;
  setShowCohortFormModal: Dispatch<SetStateAction<boolean>>;
};

const CohortFormPreview: React.FC<CohortFormPreviewProps> = ({
  config,
  isVerifying,
  setShowCohortFormModal,
}) => {
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'triggerIntegrations.fillForm.cohortFormPreview',
  });
  const {isMobile} = useScreenSize();
  const merchant = useMerchantContext();
  const firstPrompt = config.prompts[0];
  const getLocalizedString = useGetLocalizedString();

  // Dumb form functions used to preview the first prompt.
  const formProps = useForm();

  if (!firstPrompt) {
    // Never happens.
    return <Navigate to="/home" replace />;
  }

  const localizationConfig = buildLocalizationConfig(
    formatI18nLanguage(i18n.language),
    merchant.supportedLanguages,
    merchant.defaultLanguage
  );
  const localizedCta = getLocalizedString(config.ctaText) ?? t('defaultCtaText');

  return (
    <div className="flex flex-col gap-y-6">
      <div
        className="rounded-xl bg-[--xps-background-color] p-6 hover:cursor-pointer"
        onClick={() => setShowCohortFormModal(true)}
      >
        <div
          className={cn(
            'pointer-events-none mx-auto max-w-xl overflow-hidden',
            // @Devs 540px on desktop to show max two rows of cards on picture-choice prompt,
            // On mobile it depends on the size of the screen so we can't be sure what will be displayed.
            isMobile ? 'max-h-[400px]' : 'max-h-[540px]'
          )}
        >
          <FormProvider {...formProps}>
            <CohortFormPromptInput
              inPreview
              prompt={firstPrompt}
              localizationConfig={localizationConfig}
            />
          </FormProvider>
        </div>
      </div>
      <Button
        onClick={() => setShowCohortFormModal(true)}
        variant="primary"
        loading={isVerifying}
        tracking={{
          namespace: 'trigger.completion',
          metadata: {
            appId: 'cohort',
            action: 'trigger.fillForm.startForm',
          },
        }}
      >
        {localizedCta}
      </Button>
    </div>
  );
};

const CohortFillFormUsageComponent: React.FC<
  TriggerIntegrationUsageComponentProps<CohortFillFormTriggerStruct>
> = ({onTriggerIntegrationUsageSuccess, config, stepId}) => {
  const [showCohortFormModal, setShowCohortFormModal] = useState(false);
  const ongoingVerifications = useIsMutating({
    mutationKey: ['verifyStep', stepId],
  });
  const isVerifying = ongoingVerifications > 0;

  return (
    <Fragment>
      <CohortFormPreview
        config={config}
        isVerifying={isVerifying}
        setShowCohortFormModal={setShowCohortFormModal}
      />

      {showCohortFormModal && (
        <CohortFormModal
          config={config}
          onClose={() => setShowCohortFormModal(false)}
          onSubmitCallback={data => onTriggerIntegrationUsageSuccess({answers: data})}
          isLoading={isVerifying}
          trackingMetadata={{
            appId: 'cohort',
            action: 'trigger.fillForm',
            origin: 'trigger',
            stepId,
          }}
        />
      )}
    </Fragment>
  );
};

export default CohortFillFormUsageComponent;
