import Title from '@cohort/components-xps/components/Title';
import type {LocalizationConfig} from '@cohort/shared/schema/common';
import type {
  CohortFormPrompt,
  MediaCohortFormPrompt,
  PictureChoiceCohortFormPrompt,
  SelectCohortFormPrompt,
} from '@cohort/shared/schema/common/cohortForm';
import {SelectCohortFormPromptSchema} from '@cohort/shared/schema/common/cohortForm';
import {getLocalizedContentOrThrow} from '@cohort/shared/utils/localization';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {CheckboxInput} from '@cohort/wallet/components/forms/CheckboxInput';
import {FileInput, FileInputUploader} from '@cohort/wallet/components/forms/fileInput/FileInput';
import {getAcceptedAssetKindsForMediaInput} from '@cohort/wallet/components/forms/fileInput/utils';
import {Input} from '@cohort/wallet/components/forms/Input';
import {PictureChoiceInput} from '@cohort/wallet/components/forms/PictureChoiceInput';
import {ScoreInput} from '@cohort/wallet/components/forms/ScoreInput';
import {SelectPicker} from '@cohort/wallet/components/forms/SelectPicker';
import {TextArea} from '@cohort/wallet/components/forms/TextArea';
import {useScreenSize} from '@cohort/wallet/hooks/useScreenSize';
import dayjs from 'dayjs';
import React from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';
import {z} from 'zod';

type CohortFormPromptInputProps = {
  inPreview?: boolean;
  prompt: CohortFormPrompt;
  localizationConfig: LocalizationConfig;
};

const CohortFormPromptInput: React.FC<CohortFormPromptInputProps> = ({
  inPreview = false,
  prompt,
  localizationConfig,
}) => {
  const {watch, register, setValue, control} = useFormContext();
  const value = watch(prompt.id);
  const {isMobile} = useScreenSize();
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'modalStepper.cohortFormPromptInput',
  });
  const localizedName = getLocalizedContentOrThrow(prompt.name, localizationConfig);

  const inputComponent = match(prompt.type)
    .with('text', () => (
      <Input
        type="text"
        mandatory={prompt.mandatory}
        register={register(prompt.id)}
        placeholder={t('placeholderAnswerInput')}
      />
    ))
    .with('date', () => (
      <Input
        type="date"
        mandatory={prompt.mandatory}
        register={register(prompt.id, {
          setValueAs: (value: unknown) => {
            const parsed = z.coerce.date().safeParse(value);
            if (!parsed.success) {
              return null;
            }
            return parsed.data;
          },
        })}
        value={value instanceof Date ? dayjs(value).format('YYYY-MM-DD') : value?.toString()}
      />
    ))
    .with('number', () => (
      <Input
        type="number"
        mandatory={prompt.mandatory}
        placeholder={t('placeholderNumberInput')}
        register={register(prompt.id, {
          setValueAs: (value: string) => {
            const number = parseFloat(value);
            return isNaN(number) ? null : number;
          },
        })}
      />
    ))
    .with('select', () => {
      const localizedOptions = (prompt as SelectCohortFormPrompt).options?.map(option => {
        return {
          value: option.value,
          label: getLocalizedContentOrThrow(option.label, localizationConfig),
        };
      });
      if (localizedOptions === undefined) {
        throw new Error('No options for select');
      }

      if (
        SelectCohortFormPromptSchema.safeParse(prompt).success &&
        (prompt as SelectCohortFormPrompt).multipleChoice
      ) {
        if (value !== null && !Array.isArray(value)) {
          setValue(prompt.id, []);
          return null;
        }
        return (
          <SelectPicker
            mandatory={prompt.mandatory}
            value={localizedOptions.filter(option => value?.includes(option.value))}
            options={localizedOptions}
            // @Devs - Fixes a bug with react-select on mobile: https://stackoverflow.com/a/75614415 - menuPlacement="top" goes in the fix too.
            menuPortalTarget={isMobile ? null : document.body}
            menuPlacement="top"
            onChange={newValue =>
              setValue(
                prompt.id,
                newValue.map(option => option.value)
              )
            }
            isMulti={true}
            isSearchable={false}
          />
        );
      }
      return (
        <SelectPicker
          mandatory={prompt.mandatory}
          // @Devs - Fixes a bug with react-select on mobile: https://stackoverflow.com/a/75614415 - menuPlacement="top" goes in the fix too.
          menuPortalTarget={isMobile ? null : document.body}
          menuPlacement="top"
          value={localizedOptions.find(option => option.value === value)}
          options={localizedOptions}
          onChange={newValue => setValue(prompt.id, newValue?.value ?? '')}
          isSearchable={false}
        />
      );
    })
    .with('checkbox', () => (
      <div className="flex w-full justify-center gap-x-4">
        <CheckboxInput mandatory={prompt.mandatory} register={register(prompt.id)} />
        <Title className="w-fit text-xl">{localizedName}</Title>
      </div>
    ))
    .with('email', () => (
      <Input
        type="email"
        mandatory={prompt.mandatory}
        register={register(prompt.id)}
        placeholder={t('placeholderEmailInput')}
      />
    ))
    .with('long-text', () => (
      <TextArea
        mandatory={prompt.mandatory}
        register={register(prompt.id)}
        placeholder={t('placeholderAnswerInput')}
      />
    ))
    .with('score', () => (
      <ScoreInput
        mandatory={prompt.mandatory}
        name={prompt.id}
        control={control}
        className="justify-center"
      />
    ))
    .with('media', () => {
      return (
        <FileInput
          mandatory={prompt.mandatory}
          name={prompt.id}
          control={control}
          register={register}
          assetKind={getAcceptedAssetKindsForMediaInput(
            (prompt as MediaCohortFormPrompt).mediaType
          )}
        >
          <FileInputUploader />
        </FileInput>
      );
    })
    .with('picture-choice', () => {
      return (
        <PictureChoiceInput
          prompt={prompt as PictureChoiceCohortFormPrompt}
          control={control}
          register={register}
        />
      );
    })
    .exhaustive();

  const subtitle = match(prompt.type)
    .with('picture-choice', () => {
      if (
        (prompt as PictureChoiceCohortFormPrompt).multipleChoice &&
        (prompt as PictureChoiceCohortFormPrompt).maxMultipleChoices !== null
      ) {
        return t('subtitlePictureChoiceMaxChoices', {
          maxMultipleChoices: (prompt as PictureChoiceCohortFormPrompt).maxMultipleChoices,
        });
      }
      return null;
    })
    .otherwise(() => null);

  return (
    <div className="flex flex-col gap-y-6 text-center">
      {prompt.type !== 'checkbox' && (
        <div className="flex flex-col gap-1">
          <Title>{localizedName}</Title>
          {subtitle && !inPreview && <p className="text-xs">{subtitle}</p>}
        </div>
      )}
      {prompt.imageFileKey && (
        <img
          className={cn(
            'mx-auto rounded-[--xps-img-border-radius] object-cover',
            inPreview && !isMobile ? 'w-1/4' : 'w-1/2'
          )}
          src={getImageUrl(import.meta.env.COHORT_ENV, prompt.imageFileKey, {
            h: Sizes.M,
            w: Sizes.M,
          })}
        />
      )}
      <div className={cn('px-0.5', inPreview && !isMobile && 'mx-auto w-2/3')}>
        {inputComponent}
      </div>
    </div>
  );
};

export default CohortFormPromptInput;
