import type {CohortQrCodePerkIntegrationStruct} from '@cohort/shared/apps/cohort/perks/qrCode';
import {CohortQrCodePerkIntegrationSpec} from '@cohort/shared/apps/cohort/perks/qrCode';
import {CohortQrCodePerkUsageComponent} from '@cohort/wallet/apps/cohort/perkIntegrations/qrCode/PerkUsageComponent';
import type {PerkIntegration} from '@cohort/wallet/apps/PerkIntegration';

export const CohortQrCodePerkIntegration: PerkIntegration<CohortQrCodePerkIntegrationStruct> = {
  spec: CohortQrCodePerkIntegrationSpec,
  usageComponent: CohortQrCodePerkUsageComponent,
  usageType: 'modal',
};
