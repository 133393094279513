import type {EventId} from '@cohort/shared/apps';
import type {
  NotificationIntegrationSpec,
  NotificationIntegrationStruct,
} from '@cohort/shared/apps/app';
import {CohortBadgeGainedEventSpec} from '@cohort/shared/apps/cohort/userEvents/badge/badgeGained';
import {CohortBadgeLostEventSpec} from '@cohort/shared/apps/cohort/userEvents/badge/badgeLost';
import {CohortChallengeCompletedEventSpec} from '@cohort/shared/apps/cohort/userEvents/challenge/challengeCompleted';
import {CohortChallengeStepCompletedEventSpec} from '@cohort/shared/apps/cohort/userEvents/challenge/challengeStepCompleted';
import {CohortNewChallengeAvailableEventSpec} from '@cohort/shared/apps/cohort/userEvents/challenge/newChallengeAvailable';
import CohortNewChallengeStepAvailableEventSpec from '@cohort/shared/apps/cohort/userEvents/challenge/newChallengeStepAvailable';
import {CohortDigitalAssetReceivedEventSpec} from '@cohort/shared/apps/cohort/userEvents/digitalAsset/digitalAssetReceived';
import {CohortDigitalAssetTransferredEventSpec} from '@cohort/shared/apps/cohort/userEvents/digitalAsset/digitalAssetTransferred';
import {CohortPerkReceivedEventSpec} from '@cohort/shared/apps/cohort/userEvents/perk/perkReceived';
import {CohortReferralCompletedEventSpec} from '@cohort/shared/apps/cohort/userEvents/referral/referralCompleted';
import {CohortReferralCreatedEventSpec} from '@cohort/shared/apps/cohort/userEvents/referral/referralCreated';
import {CohortRewardBoxUnlockedEventSpec} from '@cohort/shared/apps/cohort/userEvents/reward/rewardBoxUnlocked';
import {CohortUserCreatedEventSpec} from '@cohort/shared/apps/cohort/userEvents/userCreated';
import {z} from 'zod';

export const CohortEmailNotificationConfigSchema = z.object({
  template: z.enum(['default', 'custom']),
});
export type CohortEmailNotificationConfig = z.infer<typeof CohortEmailNotificationConfigSchema>;

export type CohortEmailNotificationIntegrationStruct = NotificationIntegrationStruct<
  'cohort.email',
  CohortEmailNotificationConfig
>;

export const CohortEmailNotificationExecutionDataSchema = z.null();
export type CohortEmailNotificationExecutionData = z.infer<
  typeof CohortEmailNotificationExecutionDataSchema
>;

export const CohortEmailNotificationIntegrationSpec: NotificationIntegrationSpec<CohortEmailNotificationIntegrationStruct> =
  {
    id: 'cohort.email' as const,
    configSchema: CohortEmailNotificationConfigSchema,
    notificationDataSchema: z.object({}),
    notificationInputSchema: z.object({}),
    notificationExecutionDataSchema: CohortEmailNotificationExecutionDataSchema,
  };

export const COHORT_EMAIL_SUPPORTED_USER_EVENTS_SPECS_IDS = [
  CohortBadgeGainedEventSpec,
  CohortBadgeLostEventSpec,
  CohortChallengeCompletedEventSpec,
  CohortChallengeCompletedEventSpec,
  CohortChallengeStepCompletedEventSpec,
  CohortDigitalAssetReceivedEventSpec,
  CohortDigitalAssetTransferredEventSpec,
  CohortNewChallengeAvailableEventSpec,
  CohortNewChallengeAvailableEventSpec,
  CohortNewChallengeStepAvailableEventSpec,
  CohortNewChallengeStepAvailableEventSpec,
  CohortPerkReceivedEventSpec,
  CohortReferralCompletedEventSpec,
  CohortReferralCreatedEventSpec,
  CohortRewardBoxUnlockedEventSpec,
  CohortUserCreatedEventSpec,
].map(spec => spec.id as EventId);
