import {EmailSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

// @Devs, answers types from https://github.com/Typeform/js-api-client/blob/main/src/typeform-types.ts#L1001C4-L1062C4

const ChoiceSchema = z.object({
  label: z.string().optional(),
});

const TypeformAnswerTypeSchema = z.enum([
  'choice',
  'choices',
  'date',
  'email',
  'url',
  'file_url',
  'number',
  'phone_number',
  'boolean',
  'text',
  'payment',
]);
export type TypeformAnswerType = z.infer<typeof TypeformAnswerTypeSchema>;

const TypeformAnswerSchema = z.object({
  field: z
    .object({
      id: z.string(),
      type: z
        .enum([
          'address',
          'calendly',
          'contact_info',
          'date',
          'dropdown',
          'email',
          'file_upload',
          'group',
          'legal',
          'long_text',
          'matrix',
          'multiple_choice',
          'nps',
          'number',
          'opinion_scale',
          'payment',
          'phone_number',
          'picture_choice',
          'ranking',
          'rating',
          'short_text',
          'statement',
          'website',
          'yes_no',
        ])
        .optional(),
      title: z.string().optional(),
      description: z.string().optional(),
    })
    .optional(),
  type: TypeformAnswerTypeSchema.optional(),
  choice: ChoiceSchema.optional(), // Represents single choice answers for dropdown-like fields.
  choices: z // Represents multiple choice answers.
    .object({
      labels: z.array(z.string()).optional(),
      other: z.string().optional(),
    })
    .optional(),
  date: z.string().optional(),
  email: EmailSchema.optional(),
  file_url: z.string().optional(),
  phone_number: z.string().optional(),
  number: z.number().optional(),
  boolean: z.boolean().optional(),
  text: z.string().optional(),
  url: z.string().optional(),
  payment: z
    .object({
      amount: z.string().optional(),
      last4: z.string().optional(),
      name: z.string().optional(),
    })
    .optional(),
});
export type TypeformAnswer = z.infer<typeof TypeformAnswerSchema>;
const TypeformAnswersSchema = z.array(TypeformAnswerSchema);

const CalculatedSchema = z.object({
  score: z.number().optional(),
});

export const TypeformFormResponseSchema = z.object({
  response_type: z.string(),
  // @Devs, answers can be nullable when form is not accessible by the user and shows only submit button.
  answers: TypeformAnswersSchema.nullable().optional(),
  calculated: CalculatedSchema.optional(),
});
export type TypeformFormResponse = z.infer<typeof TypeformFormResponseSchema>;

export const TypeformFormFieldSchema = z.object({
  id: z.string(),
  title: z.string(),
});
export type TypeformFormField = z.infer<typeof TypeformFormFieldSchema>;

export const TypeformQuestionWithAnswerSchema = z.object({
  question: TypeformFormFieldSchema,
  answer: TypeformAnswerSchema.optional(),
});
export type TypeformQuestionWithAnswer = z.infer<typeof TypeformQuestionWithAnswerSchema>;
