import type {TypeformFormPerkIntegrationStruct} from '@cohort/shared/apps/typeform/perks/form';
import {TypeformFormPerkIntegrationSpec} from '@cohort/shared/apps/typeform/perks/form';
import type {PerkIntegration} from '@cohort/wallet/apps/PerkIntegration';
import {TypeformFormPerkUsageComponent} from '@cohort/wallet/apps/typeform/perkIntegrations/form/PerkUsageComponent';

export const TypeformFormPerkIntegration: PerkIntegration<TypeformFormPerkIntegrationStruct> = {
  spec: TypeformFormPerkIntegrationSpec,
  usageComponent: TypeformFormPerkUsageComponent,
  usageType: 'modal',
};
