import type {
  TypeformFormConfig,
  TypeformFormUsageData,
} from '@cohort/shared/apps/typeform/perks/form';
import {defaultErrorMessage} from '@cohort/shared/models';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import type {PerkUsageComponentProps} from '@cohort/wallet/apps/PerkIntegration';
import NotFoundSection from '@cohort/wallet/components/error-pages/NotFoundSection';
import useNotify from '@cohort/wallet/hooks/notify';
import {useUserStore} from '@cohort/wallet/hooks/stores/user';
import {doUsePerkAccess} from '@cohort/wallet/lib/Endpoints';
import {Widget} from '@typeform/embed-react';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

export const TypeformFormPerkUsageComponent: React.FC<PerkUsageComponentProps> = ({
  perkAccess,
  perk,
  onPerkUsageSuccess,
}) => {
  const {t} = useTranslation('app-typeform', {
    keyPrefix: 'perkIntegrations.form.perkUsageComponent',
  });
  const user = useUserStore(store => store.user);
  const notify = useNotify();
  const config = perk.integration?.config as TypeformFormConfig;

  const handleOnSubmit = useCallback(
    (payload: {responseId: string}): void => {
      doUsePerkAccess(perkAccess.id, null, {
        responseId: payload.responseId,
      } as TypeformFormUsageData)
        .then(() => {
          onPerkUsageSuccess();
        })
        .catch(err => {
          if (isCohortError(err, 'perk.usages-limit-reached')) {
            notify('error', t('maxUsagesReached'), {autoClose: false});
            return;
          }
          notify('error', defaultErrorMessage);
        });
    },
    [onPerkUsageSuccess, perkAccess, notify, t]
  );

  if (user === null) {
    return <NotFoundSection />;
  }

  return (
    <Widget
      id={config.formId}
      inlineOnMobile
      height={550}
      style={{width: '100%'}}
      onSubmit={handleOnSubmit}
      hidden={{cohort_user_id: user.id}}
    />
  );
};
