import type {TalonOneCouponPerkIntegrationStruct} from '@cohort/shared/apps/talon-one/perks/coupon';
import {TalonOneCouponPerkIntegrationSpec} from '@cohort/shared/apps/talon-one/perks/coupon';
import type {PerkIntegration} from '@cohort/wallet/apps/PerkIntegration';
import {TalonOneCouponPerkUsageComponent} from '@cohort/wallet/apps/talon-one/perkIntegrations/coupon/PerkUsageComponent';

export const TalonOneCouponPerkIntegration: PerkIntegration<TalonOneCouponPerkIntegrationStruct> = {
  spec: TalonOneCouponPerkIntegrationSpec,
  usageComponent: TalonOneCouponPerkUsageComponent,
  usageType: 'modal',
};
