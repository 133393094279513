import type {CohortFormPerkIntegrationStruct} from '@cohort/shared/apps/cohort-form/perks/fillForm';
import {CohortFormPerkIntegrationSpec} from '@cohort/shared/apps/cohort-form/perks/fillForm';
import {CohortFormPerkUsageComponent} from '@cohort/wallet/apps/cohort-form/perkIntegrations/fillForm/PerkUsageComponent';
import type {PerkIntegration} from '@cohort/wallet/apps/PerkIntegration';

export const CohortFormPerkIntegration: PerkIntegration<CohortFormPerkIntegrationStruct> = {
  spec: CohortFormPerkIntegrationSpec,
  usageComponent: CohortFormPerkUsageComponent,
  usageType: 'self',
};
