import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {LocalizedShortStringSchema, LocalizedStringSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

const BottomActionLabelSchema = LocalizedShortStringSchema;
export const CohortSwipeCardsTriggerConfigSchema = z.object({
  actionCtaLabel: LocalizedStringSchema,
  title: LocalizedStringSchema,
  leftBtnLabel: BottomActionLabelSchema,
  rightBtnLabel: BottomActionLabelSchema,
  cards: z
    .array(
      z.object({
        id: z.string().uuid(),
        title: LocalizedShortStringSchema.optional(),
        imageFileKey: z.string(),
      })
    )
    .min(1),
});
export type CohortSwipeCardsTriggerConfig = z.infer<typeof CohortSwipeCardsTriggerConfigSchema>;

const CohortSwipeCardsTriggerAnswerDataSchema = z.object({
  cardId: z.string().uuid(),
  cardTitle: z.string().optional(),
  cardImageFileKey: z.string(),
  answer: z.string(),
});

export const CohortSwipeCardsVerificationAttemptInputSchema = z.object({
  answers: z.record(z.string().uuid(), CohortSwipeCardsTriggerAnswerDataSchema),
});
export type CohortSwipeCardsVerificationAttemptInput = z.infer<
  typeof CohortSwipeCardsVerificationAttemptInputSchema
>;

export const ExposedCohortSwipeCardsTriggerAnswerDataSchema =
  CohortSwipeCardsTriggerAnswerDataSchema;
export type ExposedCohortSwipeCardsTriggerAnswerData = z.infer<
  typeof ExposedCohortSwipeCardsTriggerAnswerDataSchema
>;

export const CohortSwipeCardsVerificationAttemptDataSchema = z.object({
  answers: z.record(z.string().uuid(), ExposedCohortSwipeCardsTriggerAnswerDataSchema),
});
export type CohortSwipeCardsVerificationAttemptData = z.infer<
  typeof CohortSwipeCardsVerificationAttemptDataSchema
>;

export type CohortSwipeCardsTriggerStruct = TriggerIntegrationStruct<
  'cohort-form.swipe-cards',
  CohortSwipeCardsTriggerConfig,
  CohortSwipeCardsVerificationAttemptData
>;

export const CohortSwipeCardsTriggerIntegrationSpec: TriggerIntegrationSpec<CohortSwipeCardsTriggerStruct> =
  {
    id: 'cohort-form.swipe-cards' as const,
    configurableMaxAttempts: false,
    configSchema: CohortSwipeCardsTriggerConfigSchema,
    verificationAttemptDataSchema: CohortSwipeCardsVerificationAttemptDataSchema,
    verificationAttemptInputSchema: CohortSwipeCardsVerificationAttemptInputSchema,
    requiresMerchantConnection: false,
  };
