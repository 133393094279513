import type {SyncIntegrationSpec, SyncIntegrationStruct} from '@cohort/shared/apps/sync';
import {BaseUserEventExportConfigSchema} from '@cohort/shared/apps/sync';
import {z} from 'zod';

export const MaxxingUserEventExportConfigSchema = BaseUserEventExportConfigSchema.extend({
  allowCustomerSearch: z.boolean(), // Opt-in to querying the Maxxing API to get the loyalty card number
  loyaltyCardNumberUserPropertyId: z.string().nullable(), // The ID of the user property that contains the loyalty card number
});
export type MaxxingUserEventExportConfig = z.infer<typeof MaxxingUserEventExportConfigSchema>;

export type MaxxingSyncStruct = SyncIntegrationStruct<
  never,
  never,
  never,
  MaxxingUserEventExportConfig,
  never,
  never
>;

export const MaxxingSyncSpec: SyncIntegrationSpec<MaxxingSyncStruct> = {
  contentSyncConfigSchema: null,
  userSyncsConfigSchema: null,
  userSyncsStateSchema: null,
  userEventExportConfigSchema: MaxxingUserEventExportConfigSchema,
  userExportConfigSchema: null,
  userExportStateSchema: null,
};
