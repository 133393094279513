import type {SyncIntegrationSpec, SyncIntegrationStruct} from '@cohort/shared/apps/sync';
import {z} from 'zod';

export const ShopifyUserSyncConfigSchema = z.object({
  createUsers: z.boolean(),
});
export type ShopifyUserSyncConfig = z.infer<typeof ShopifyUserSyncConfigSchema>;

export const ShopifyUserSyncStateSchema = z.object({
  lastUpdatedAt: z.string().nullable(),
});
export type ShopifyUserSyncState = z.infer<typeof ShopifyUserSyncStateSchema>;

export const ShopifyUserExportConfigSchema = z.object({
  createCustomers: z.boolean(),
  createCustomerSegments: z.boolean(),
});
export type ShopifyUserExportConfig = z.infer<typeof ShopifyUserExportConfigSchema>;

/**
 * customerIdAttributeLastCreatedAt: The creation date of the last customer synced
 * cohortsLastUpdatedAt: The date of last user-event cohort.joined or cohort.left synced
 * cohortsSegmentIds: The customer segments created for each Cohort. Record<Cohort['referenceId'], SegmentId (external)>
 */
export const ShopifyUserExportStateSchema = z.object({
  customerIdAttributeLastCreatedAt: z.string().nullable(),
  cohortsLastUpdatedAt: z.string().nullable(),
  cohortsSegmentIds: z.record(z.string(), z.string().optional()),
});
export type ShopifyUserExportState = z.infer<typeof ShopifyUserExportStateSchema>;

export type ShopifySyncStruct = SyncIntegrationStruct<
  ShopifyUserSyncConfig,
  ShopifyUserSyncState,
  never,
  never,
  ShopifyUserExportConfig,
  ShopifyUserExportState
>;

export const ShopifySyncIntegrationSpec: SyncIntegrationSpec<ShopifySyncStruct> = {
  userSyncsConfigSchema: ShopifyUserSyncConfigSchema,
  userSyncsStateSchema: ShopifyUserSyncStateSchema,
  contentSyncConfigSchema: null,
  userEventExportConfigSchema: null,
  userExportConfigSchema: ShopifyUserExportConfigSchema,
  userExportStateSchema: ShopifyUserExportStateSchema,
};
