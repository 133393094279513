import type {AppSpec, AppStruct} from '@cohort/shared/apps/app';
import type {MaxxingConnectorStruct} from '@cohort/shared/apps/maxxing/connector';
import {MaxxingConnectorSpec} from '@cohort/shared/apps/maxxing/connector';
import type {MaxxingSyncStruct} from '@cohort/shared/apps/maxxing/sync';
import {MaxxingSyncSpec} from '@cohort/shared/apps/maxxing/sync';

export const MAXXING_APP_ID = 'maxxing';
export const MAXXING_APP_NAME = 'Maxxing';

export type MaxxingAppStruct = AppStruct<
  'maxxing',
  MaxxingConnectorStruct,
  null,
  MaxxingSyncStruct,
  never,
  never,
  never,
  never,
  never,
  never,
  never,
  never
>;

export const MaxxingAppSpec: AppSpec<MaxxingAppStruct> = {
  id: MAXXING_APP_ID,
  name: MAXXING_APP_NAME,
  documentationUrl: 'https://docs.getcohort.com/integrations/apps/maxxing',
  merchantConnector: MaxxingConnectorSpec,
  userConnector: null,
  syncSpec: MaxxingSyncSpec,
  actionSpecs: [],
  notificationIntegrationSpecs: [],
  perkIntegrationSpecs: [],
  triggerIntegrationSpecs: [],
  userEventSpecs: [],
  userPropertySpecs: [],
  resourceSpecs: [],
  mediaSpecs: [],
};
