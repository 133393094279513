import {cn} from '@cohort/shared-frontend/utils/classNames';
import type {ButtonProps} from '@cohort/wallet/components/button/Button';
import Button from '@cohort/wallet/components/button/Button';
import {cva} from 'class-variance-authority';
import React from 'react';

const progressButtonVariants = cva(
  cn('absolute left-0 top-0 h-full w-full transition-transform duration-300 ease-in-out'),
  {
    variants: {
      variant: {
        primary: cn('bg-[--xps-primary-btn-hover-background-color]'),
        secondary: cn('bg-[--xps-secondary-btn-hover-background-color]'),
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  }
);

interface ProgressButtonProps extends ButtonProps {
  progress: number;
  variant?: 'primary' | 'secondary';
}

const ProgressButton = React.forwardRef<HTMLButtonElement, ProgressButtonProps>(
  (
    {progress, variant = 'primary', className, loading, children, size = 'default', ...props},
    ref
  ) => {
    const progressPercent = Math.min(Math.max(Math.round(progress), 0), 100);

    return (
      <Button
        {...props}
        ref={ref}
        variant={variant}
        size={size}
        disabled={loading}
        className={cn(
          'relative flex items-center justify-center overflow-hidden disabled:!opacity-90',
          className
        )}
      >
        <div
          className={cn(progressButtonVariants({variant}), loading ? 'opacity-100' : 'opacity-0')}
          style={{transform: `translateX(${progressPercent - 100}%)`}}
        />
        <span className="relative">{loading ? `${progressPercent}%` : children}</span>
      </Button>
    );
  }
);

export default ProgressButton;
