import {
  getSessionId,
  Identify,
  identify,
  init as initAmplitude,
  reset as resetAmplitude,
  setUserId,
  track,
} from '@amplitude/analytics-browser';
import type {CampaignChallengeWDto, CampaignStoreWDto} from '@cohort/wallet-schemas/campaign';
import type {MerchantWDto} from '@cohort/wallet-schemas/merchant';
import type {UserWDto} from '@cohort/wallet-schemas/user';
import {datadogRum} from '@datadog/browser-rum';
import {
  captureException,
  getCurrentScope,
  init,
  reactRouterV6BrowserTracingIntegration,
} from '@sentry/react';
import {uuid4} from '@sentry/utils';
import {useEffect} from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const COHORT_ENV = import.meta.env.COHORT_ENV;
const SHOULD_TRACK = !['development'].includes(COHORT_ENV);

const IS_PROD = COHORT_ENV === 'prod';

let TRACE_ID: string | undefined;

export const getTraceId = (): string => {
  if (TRACE_ID === undefined) {
    TRACE_ID = uuid4();
  }
  return TRACE_ID;
};

export const getAmplitudeSessionId = (): number | undefined => {
  return getSessionId();
};

const setAmplitudeTraceId = (): void => {
  const identifyEvent = new Identify();

  identifyEvent.setOnce('traceId', getTraceId());
  identify(identifyEvent);
};

const loadAmplitude = (): void => {
  initAmplitude(import.meta.env.COHORT_AMPLITUDE_API_KEY, {
    defaultTracking: {
      attribution: false,
      formInteractions: false,
      pageViews: false,
      sessions: true,
      fileDownloads: true,
    },
    serverUrl: 'https://amp-eu.getcohort.com/2/httpapi',
    serverZone: 'EU',
  });
  setAmplitudeTraceId();
};

const loadSentry = (): void => {
  init({
    dsn: import.meta.env.COHORT_SENTRY_DSN,
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      // uncomment me one day if we pay for Session Replay
      // new Replay(),
    ],
    environment: COHORT_ENV,
    autoSessionTracking: true,
    release: GIT_COMMIT_SHA,
    debug: false,
    tracesSampleRate: IS_PROD ? 0.1 : 1,
    // uncomment me one day if we pay for Session Replay
    // replaysOnErrorSampleRate: 1,
    // replaysSessionSampleRate: 0.1,
    enabled: COHORT_ENV !== 'development',
    // uncomment me to test Sentry in development
    // enabled: true,
  });
  getCurrentScope().setTag('trace_id', getTraceId());
};

const loadRum = (): void => {
  datadogRum.init({
    applicationId: 'cfec4067-0122-47b0-bc37-3fc806cea5b3',
    clientToken: 'pub9f38301b3b13f1ab172fc7b08b755b89',
    site: 'datadoghq.eu',
    service: 'wallet',
    env: COHORT_ENV,
    version: GIT_COMMIT_SHA,
    sessionSampleRate: IS_PROD ? 100 : 10,
    sessionReplaySampleRate: IS_PROD ? 5 : 0,
    trackUserInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  datadogRum.setGlobalContextProperty('trace_id', getTraceId());
  datadogRum.startSessionReplayRecording();
};

export const trackError = (err: unknown, metadata?: Record<string, unknown>): void => {
  if (COHORT_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(err);
  } else {
    captureException(err, {
      contexts: {
        metadata,
      },
    });
  }
};

export const initTracking = (): void => {
  loadAmplitude();
  loadSentry();
  if (COHORT_ENV === 'development') {
    return;
  }
  loadRum();
};

const setAmplitudeUser = (user: UserWDto | undefined): void => {
  if (user !== undefined) {
    setUserId(user.id);
  } else {
    resetAmplitude();
    setAmplitudeTraceId();
  }
};

export type SetAmplitudeMetadataContext = {
  rootPath: 'space' | 'store' | 'challenges';
  merchant: MerchantWDto;
  campaign?: CampaignStoreWDto;
  challenge?: CampaignChallengeWDto;
};

export const setAmplitudeMetadata = (context: SetAmplitudeMetadataContext): void => {
  const {merchant, campaign, challenge} = context;
  const identifyEvent = new Identify();

  identifyEvent.setOnce('merchantId', merchant.id);
  identifyEvent.setOnce('mode', merchant.mode);
  identifyEvent.setOnce('rootPath', context.rootPath);

  if (campaign !== undefined) {
    identifyEvent.setOnce('campaignId', campaign.id);
  }
  if (challenge !== undefined) {
    identifyEvent.setOnce('challengeId', challenge.id);
  }
  identify(identifyEvent);
};

const setRumUser = (user: UserWDto | undefined): void => {
  if (user !== undefined) {
    datadogRum.setUser({
      id: user.id,
      email: user.email,
    });
  } else {
    datadogRum.clearUser();
  }
};

export const setCurrentUser = (user: UserWDto | undefined): void => {
  setAmplitudeUser(user);
  if (SHOULD_TRACK) {
    setRumUser(user);
  }
};

export const trackEvent = (eventName: string, properties?: Record<string, unknown>): void => {
  if (COHORT_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(`Sending ${eventName}`);
  }
  track(eventName, properties);
};

export type AmplitudeEventNamespace =
  | 'apps'
  | 'badges.list'
  | 'cohortForm'
  | 'digitalAssets.detail'
  | 'digitalAssets.import'
  | 'digitalAssets.list'
  | 'digitalAssets.transfer'
  | 'digitalAssets.transferRequest'
  | 'errorBoundary'
  | 'footer'
  | 'challenges.card'
  | 'challenges.detail'
  | 'challenges.list'
  | 'challenges.login'
  | 'challenges.referral'
  | 'contents.overview.seeAll'
  | 'home'
  | 'contents.overview'
  | 'contentCategories.detail'
  | 'contentCategories.overview'
  | 'login'
  | 'modals.apps'
  | 'modals.badgeDetail'
  | 'modals.challenges.qrCodeScanner'
  | 'modals.challenges.qrCodeScannerDesktopWarning'
  | 'modals.checkout'
  | 'modals.cohortForm'
  | 'modals.digitalAssetTransfer'
  | 'modals.error'
  | 'modals.help'
  | 'modals.challenges'
  | 'modals.contents.detail'
  | 'modals.manageData'
  | 'modals.perks'
  | 'modals.previews.digitalAsset'
  | 'modals.previews.perk'
  | 'modals.triggerIntegrations.cohortForm'
  | 'navbar'
  | 'notfound'
  | 'perks.list'
  | 'perks.detail'
  | 'perks.usage'
  | 'rewards.card'
  | 'rewards.list'
  | 'rewardBoxes.card'
  | 'rewardBoxes.detail'
  | 'settings'
  | 'store.buy'
  | 'store.login'
  | 'store.campaign'
  | 'store.checkout'
  | 'store.orderPage'
  | 'tabbar'
  | 'toasts.newVersion'
  | 'trigger.completion';

export type TrackingConfig = {
  namespace: `${AmplitudeEventNamespace}.${string}` | AmplitudeEventNamespace;
  metadata?: Record<string, unknown>;
};
