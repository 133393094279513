import {CohortQrCodeAccessDataSchema} from '@cohort/shared/apps/cohort/perks/qrCode';
import {defaultErrorMessage} from '@cohort/shared/models';
import {getTextColor} from '@cohort/shared/schema/common';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import type {PerkUsageComponentProps} from '@cohort/wallet/apps/PerkIntegration';
import Button from '@cohort/wallet/components/button/Button';
import {usePerkAccess} from '@cohort/wallet/hooks/api/PerkAccesses';
import {useCohortMutation} from '@cohort/wallet/hooks/api/Query';
import useNotify from '@cohort/wallet/hooks/notify';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import {activatePerkAccess} from '@cohort/wallet/lib/Endpoints';
import {formatDuration} from '@cohort/wallet/lib/Utils';
import {QRCodeCanvas} from 'qrcode.react';
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

export const CohortQrCodePerkUsageComponent: React.FC<PerkUsageComponentProps> = props => {
  const {
    perk,
    onClose,
    onPerkUsageSuccess,
    perkAccess: {id: perkAccessId},
  } = props;

  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'perkIntegrations.qr-code.perkUsageComponent',
  });
  const [countdown, setCountdown] = useState<number>();
  const {hasDarkBg, backgroundColor} = useThemeContext();
  const notify = useNotify();
  const initialized = useRef(false);
  const tokenUsed = useRef(false);
  const [tokenCode, setTokenCode] = useState<string>();
  const intervalRef = useRef<NodeJS.Timeout>();

  const {data: perkAccess, refetch} = usePerkAccess(perkAccessId, {
    autoRefresh: tokenCode !== undefined,
  });

  const perkAccessData =
    perkAccess !== undefined ? CohortQrCodeAccessDataSchema.parse(perkAccess.data) : undefined;

  const token = perkAccessData?.token;

  const {mutate: refreshQrCode} = useCohortMutation({
    mutationFn: async () => activatePerkAccess(perkAccessId),
    onSuccess: ({data}) => {
      const token = CohortQrCodeAccessDataSchema.parse(data).token;
      setTokenCode(token?.code);
      refetch();
    },
    onError: err => {
      if (isCohortError(err, 'perk.usages-limit-reached')) {
        notify('error', t('maxUsagesReached'), {autoClose: false});
        return;
      }
      notify('error', defaultErrorMessage);
    },
  });

  useEffect(() => {
    if (tokenUsed.current) {
      return;
    }
    if (tokenCode !== undefined && tokenCode === token?.code && token.used) {
      onPerkUsageSuccess();
      return () => {
        tokenUsed.current = true;
      };
    }
    return;
  }, [onPerkUsageSuccess, token?.code, token?.used, tokenCode]);

  useEffect(() => {
    if (initialized.current || perkAccessData === undefined) {
      return;
    }
    if (perkAccess?.canBeUsed === false) {
      onClose();
      return;
    }
    refreshQrCode();
    return () => {
      initialized.current = true;
    };
  }, [
    onClose,
    perkAccess?.canBeUsed,
    perkAccessData,
    refreshQrCode,
    token,
    token?.expiration,
    token?.used,
  ]);

  useEffect(() => {
    if (token?.expiration === undefined) {
      return;
    }
    const interval = setInterval(() => {
      const now = Math.floor(Date.now() / 1000);
      setCountdown(token.expiration - now);
    }, 1000);
    intervalRef.current = interval;
    return () => clearInterval(interval);
  }, [token?.expiration]);

  useEffect(() => {
    if (countdown !== undefined && countdown <= 0 && intervalRef.current !== undefined) {
      clearInterval(intervalRef.current);
    }
  }, [countdown]);

  if (token === undefined) {
    return <Fragment />;
  }

  const textColor = getTextColor(backgroundColor);
  const qrCodeFg = hasDarkBg ? backgroundColor : textColor;
  const qrCodeBg = hasDarkBg ? textColor : backgroundColor;

  return (
    <div className="w-full text-center">
      <QRCodeCanvas
        fgColor={qrCodeFg}
        bgColor={qrCodeBg}
        className="mx-auto rounded-lg border-2 p-2"
        style={{
          borderColor: textColor,
          backgroundColor: qrCodeBg,
        }}
        value={token.code}
        size={300}
      />
      <div className="mt-4 text-sm">
        {countdown !== undefined && countdown > 0 && (
          <p className={textColor}>{t('labelCountdown', {countdown: formatDuration(countdown)})}</p>
        )}
        {countdown !== undefined && countdown <= 0 && (
          <p className="text-red-700">{t('labelExpired')}</p>
        )}
      </div>
      <div className="mt-4 flex flex-col gap-4">
        <Button
          variant="primary"
          onClick={() => refreshQrCode()}
          className="outline-0 focus:ring-0 focus:ring-offset-0"
          tracking={{
            namespace: 'perks.usage',
            metadata: {
              perkIntegrationId: perk.integration?.perkIntegrationId,
              action: 'refreshCode',
              perkAccessId,
              perkId: perk.id,
            },
          }}
        >
          {t('buttonRefresh')}
        </Button>

        <Button
          variant="secondary"
          onClick={onClose}
          className="mt-3 outline-0 focus:ring-0 focus:ring-offset-0 md:mt-0"
          tracking={{
            namespace: 'perks.usage',
            metadata: {
              perkIntegrationId: perk.integration?.perkIntegrationId,
              action: 'close',
              perkAccessId,
              perkId: perk.id,
            },
          }}
        >
          {t('buttonClose')}
        </Button>
      </div>
    </div>
  );
};
