import type {CohortSwipeCardsTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/swipeCards';
import TinderSwipe from '@cohort/wallet/apps/cohort-form/triggerIntegrations/swipeCards/TinderSwipe';
import type {TriggerIntegrationUsageComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import Button from '@cohort/wallet/components/button/Button';
import {ModalHeader, ModalOrDrawer} from '@cohort/wallet/components/modals/Modal';
import useGetLocalizedString from '@cohort/wallet/hooks/getLocalizedString';
import {useState} from 'react';

const CohortSwipeCardsUsageComponent: React.FC<
  TriggerIntegrationUsageComponentProps<CohortSwipeCardsTriggerStruct>
> = ({onTriggerIntegrationUsageSuccess, config, stepId}) => {
  const [tinderModal, setTinderModal] = useState(false);
  const getLocalizedString = useGetLocalizedString();

  const actionCtaLabel = getLocalizedString(config.actionCtaLabel) ?? '';
  const title = getLocalizedString(config.title) ?? '';
  const leftBtnLabel = getLocalizedString(config.leftBtnLabel) ?? '';
  const rightBtnLabel = getLocalizedString(config.rightBtnLabel) ?? '';
  const cards = config.cards;
  const tinderConfig = {
    title,
    leftBtnLabel,
    rightBtnLabel,
    cards,
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="rounded-xl bg-[--xps-background-color] p-4">
        <TinderSwipe {...tinderConfig} preview={true} />
        {tinderModal && (
          <ModalOrDrawer
            tracking={{
              namespace: 'modals.apps.cohort-form.tinder',
            }}
          >
            <ModalHeader onClose={() => setTinderModal(false)} />
            <TinderSwipe
              {...tinderConfig}
              preview={false}
              onSuccess={answers => onTriggerIntegrationUsageSuccess({answers})}
              onClose={() => setTinderModal(false)}
            />
          </ModalOrDrawer>
        )}
      </div>
      <Button
        variant="primary"
        size="small"
        className="w-full"
        tracking={{
          namespace: 'modals.apps.cohort-form.tinder.open',
          metadata: {
            stepId,
          },
        }}
        onClick={() => setTinderModal(true)}
      >
        {actionCtaLabel}
      </Button>
    </div>
  );
};

export default CohortSwipeCardsUsageComponent;
