import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortReferralCreatedPropertiesSchema = z.object({
  campaignId: z.string(),
  campaignInternalName: z.string(),
  referrerUserId: z.string(),
  refereeUserId: z.string(),
});
export type CohortReferralCreatedProperties = z.infer<typeof CohortReferralCreatedPropertiesSchema>;

export type CohortReferralCreatedEventStruct = UserEventStruct<
  'cohort.referral.created',
  keyof CohortReferralCreatedProperties,
  CohortReferralCreatedProperties,
  CohortReferralCreatedProperties
>;

export const CohortReferralCreatedEventSpec: UserEventSpec<CohortReferralCreatedEventStruct> = {
  id: 'cohort.referral.created',
  name: 'Created a referral',
  description: 'Event Triggerred when a user creates a referral',
  propertiesSchema: CohortReferralCreatedPropertiesSchema,
  exposedPropertiesSchema: CohortReferralCreatedPropertiesSchema,
  resources: {},
  rulesEngineConfig: {
    isVisible: true,
    exposedFields: [],
  },
};
