import {cn} from '@cohort/shared-frontend/utils/classNames';
import usePageViewTracker from '@cohort/wallet/hooks/pageViewTracker';
import {useScreenSize} from '@cohort/wallet/hooks/useScreenSize';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import type {TrackingConfig} from '@cohort/wallet/lib/Tracking';
import {Dialog, Transition} from '@headlessui/react';
import {XCircle} from '@phosphor-icons/react';
import type {MouseEvent} from 'react';
import {Fragment} from 'react';
import {createPortal} from 'react-dom';
import {Drawer} from 'vaul';

export type DrawerModalProps = BaseModalProps & {
  height?: 'full' | 'fit-content';
  allowBackgroundScroll?: boolean;
  withPaddingBottom?: boolean;
};

const DrawerModal = ({
  title,
  subtitle,
  onClose,
  children,
  tracking,
  height = 'fit-content',
  allowBackgroundScroll = false,
  withPaddingBottom = true,
}: DrawerModalProps): JSX.Element => {
  const {backgroundColor} = useThemeContext();
  const dismissible = !!onClose;

  usePageViewTracker(tracking.namespace, tracking.metadata);

  return (
    <Drawer.Root
      open={true}
      onClose={onClose}
      dismissible={dismissible}
      modal={!allowBackgroundScroll}
    >
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 z-50 bg-black/40" />
        <Drawer.Content
          className={cn(
            'fixed bottom-0 left-0 right-0 z-50 flex max-h-[96vh] flex-col gap-4 rounded-t-[var(--xps-modal-border-radius)] px-6 pt-4 *:px-2',
            height === 'full' && '!h-[96vh] !min-h-[96vh]'
          )}
          style={{backgroundColor: backgroundColor}}
        >
          <div className="flex flex-col gap-2">
            <div className="mx-auto h-1.5 w-12 flex-shrink-0 rounded-full bg-zinc-300" />
            {title}
            {subtitle}
          </div>
          <div
            className={cn('no-scrollbar flex-grow overflow-y-auto', withPaddingBottom && 'pb-8')}
          >
            {children}
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export type BaseModalProps = {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
  onClose?: () => void;
  className?: string;
  tracking: TrackingConfig;
  width?: number;
};

const BaseModal = ({
  title,
  subtitle,
  children,
  onClose,
  className,
  tracking,
  width,
}: BaseModalProps): JSX.Element => {
  const {backgroundColor} = useThemeContext();

  usePageViewTracker(tracking.namespace, tracking.metadata);

  return createPortal(
    <Transition.Root appear={true} show={true} as={'div'}>
      <Dialog
        className="relative z-50 cursor-default"
        onClick={(e: MouseEvent) => {
          e.preventDefault();
        }}
        onClose={() => onClose && onClose()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div tabIndex={0} className="flex h-full items-center justify-center px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                style={{
                  backgroundColor: backgroundColor,
                  borderRadius: 'var(--xps-modal-border-radius)',
                  ...(width && {width: `${width}px`}),
                }}
                className={cn(
                  'relative flex max-h-[--modal-max-height-mobile] flex-col gap-4 px-6 py-8 shadow-xl *:px-2 md:max-h-[--modal-max-height]',
                  !width && 'min-w-[50vw]',
                  className
                )}
              >
                {(title || subtitle) && (
                  <div className="flex flex-col">
                    <div className="flex items-center justify-between">{title}</div>
                    {subtitle}
                  </div>
                )}
                <div className="no-scrollbar flex grow flex-col overflow-y-auto">{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>,
    document.body
  );
};

type ModalOrDrawerProps = {
  children: React.ReactNode;
  tracking: TrackingConfig;
  onClose?: () => void;
  modalProps?: Partial<BaseModalProps>;
  drawerProps?: Partial<DrawerModalProps>;
};

const ModalOrDrawer: React.FC<ModalOrDrawerProps> = ({
  children,
  tracking,
  onClose,
  modalProps,
  drawerProps,
}) => {
  const {isMobile} = useScreenSize();

  if (isMobile) {
    return (
      <DrawerModal tracking={tracking} onClose={onClose} {...drawerProps}>
        {children}
      </DrawerModal>
    );
  }
  return (
    <BaseModal tracking={tracking} onClose={onClose} {...modalProps}>
      {children}
    </BaseModal>
  );
};

const ModalFooter: React.FC<{className?: string; children: React.ReactNode}> = ({
  children,
  className,
}) => {
  const {isMobile} = useScreenSize();

  return (
    <div
      className={cn(
        'flex flex-col border-t border-[--xps-border-color] text-sm font-normal',
        isMobile ? 'pt-4' : 'pt-6',
        className
      )}
    >
      {children}
    </div>
  );
};

type ModalHeaderProps = {
  children?: React.ReactNode;
  onClose?: () => void;
  hideCloseButton?: boolean;
};

const ModalHeader: React.FC<ModalHeaderProps> = ({onClose, children, hideCloseButton}) => {
  const {accentColor} = useThemeContext();
  const {isMobile} = useScreenSize();

  if (!onClose || hideCloseButton) {
    return <div className="grid grid-cols-6">{children}</div>;
  }

  return (
    <div className={cn('grid grid-cols-6', isMobile ? 'mb-4' : 'mb-1')}>
      <div className="col-span-5">{children}</div>
      <div className="col-span-1">
        <div className="col-span-1 flex cursor-pointer justify-end">
          <XCircle size={32} weight="fill" onClick={() => onClose()} style={{color: accentColor}} />
        </div>
      </div>
    </div>
  );
};

export {BaseModal, DrawerModal, ModalOrDrawer, ModalFooter, ModalHeader};
